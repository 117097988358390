html, body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 17px;
 
}

/* Track (the background) */
::-webkit-scrollbar-track {
  background: #424242; /* Dark background */


}

/* Handle (the moving part) */
::-webkit-scrollbar-thumb {
  background: #676767; /* Blue color */
  border-radius: 2px;
  border: 2px solid #424242;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}



::-webkit-font-smoothing {
  display: antialiased;
}
::-moz-osx-font-smoothing {
  display: grayscale;
}

.alert-enter {
  opacity: 0;
  transition: opacity 3000ms ease-in;
}

.alert-enter-active {
  opacity: 1;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transition: opacity 3000ms ease-in;
}

/* Add this to your CSS file */
.fade-in {
  opacity: 0;
  transform: scale(0.95) translateX(20px); /* Start smaller and to the right */
  animation: fadeInGrowSlideAnimation ease 0.5s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeOutAnimation ease 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
  100% {
    opacity: 0;
    transform: scale(0.75) translateX(20px);
  }
}

@keyframes fadeInGrowSlideAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75) translateX(20px); /* Initial state */
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(0); /* Final state: full size and original position */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* image blur effect */

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.image-container img {
  display: block;
  transition: filter 0.5s linear;
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
}
